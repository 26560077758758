/* eslint-disable */
import {computed} from "@vue/composition-api";
import VueCompositionApi from '@vue/composition-api'
import {i18n} from '@/libs/i18n';
import {useUtils as useI18nUtils} from '@core/libs/i18n';

const {i18n: i18nFunc} = useI18nUtils();
import Vue from "vue";

Vue.use(VueCompositionApi)

function i18nT(str) {
  return i18nFunc(str);
}

export default [
  {
    path: '/ac/finances',
    name: 'finances',
    component: () => import('@/views/accounting/Finances/Finances.vue'),
    meta: {
      module: 'finances',
      pageTitle: computed(() => i18nT(`Finances`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Finances`)),
          to: '/ac/accounting',
        },
      ],
    },
  },
  {
    path: '/ac/finances/:listMode',
    name: 'finances-list',
    component: () => import('@/views/accounting/Finances/Finances.vue'),
    meta: {
      module: 'finances',
      pageTitle: computed(() => i18nT(`Finances`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Finances`)),
          to: '/ac/accounting',
        },
      ],
    },
  },
  {
    path: '/ac/finances/preview/:id',
    name: 'finance-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
    meta: {
      navActiveLink: 'finances',
      module: 'finances',
      pageTitle: computed(() => i18nT(`Invoice Preview`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Finances`)),
          to: '/ac/finances',
        },
        {
          text: computed(() => i18nT(`Preview Invoice`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ac/finances/edit/expense/:id',
    name: 'edit-expense',
    component: () => import('@/views/accounting/Finances/FinanceEditDocument.vue'),
    meta: {
      navActiveLink: 'finances',
      module: 'invoices',
      mode: 'write',
      pageTitle: computed(() => i18nT(`Edit Invoice`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Finances`)),
          to: '/ac/finances',
        },
        {
          text: computed(() => i18nT(`Edit Expense`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ac/finances/create/expense/',
    name: 'create-expense',
    component: () => import('@/views/accounting/Finances/FinanceEditDocument.vue'),
    meta: {
      navActiveLink: 'finances',
      module: 'invoices',
      mode: 'write',
      pageTitle: computed(() => i18nT(`Add expense`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Finances`)),
          to: '/ac/finances',
        },
        {
          text: computed(() => i18nT(`Add expense`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ac/finances/edit/income/:id',
    name: 'edit-income',
    component: () => import('@/views/accounting/Finances/FinanceEditDocument.vue'),
    meta: {
      navActiveLink: 'finances',
      module: 'invoices',
      mode: 'write',
      pageTitle: computed(() => i18nT(`Edit Income`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Finances`)),
          to: '/ac/finances',
        },
        {
          text: computed(() => i18nT(`Edit Income`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ac/finances/create/income/',
    name: 'create-income',
    component: () => import('@/views/accounting/Finances/FinanceEditDocument.vue'),
    meta: {
      navActiveLink: 'finances',
      module: 'invoices',
      mode: 'write',
      pageTitle: computed(() => i18nT(`Add income`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Finances`)),
          to: '/ac/finances',
        },
        {
          text: computed(() => i18nT(`Add income`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ac/payments',
    name: 'payments',
    component: () => import('@/views/accounting/Invoices/InvoiceList.vue'),
    meta: {
      module: 'payments',
      pageTitle: computed(() => i18nT(`Payments`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Payments`)),
          to: '/ac/payments',
        },
      ],
    },
  },
  {
    path: '/ac/invoices',
    name: 'invoices',
    component: () => import('@/views/accounting/Invoices/InvoiceList.vue'),
    meta: {
      module: 'invoices',
      pageTitle: computed(() => i18nT(`Invoices`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Invoices`)),
          to: '/ac/invoices',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ac/invoices/preview/:id',
    name: 'invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
    meta: {
      navActiveLink: 'invoices',
      module: 'invoices',
      pageTitle: computed(() => i18nT(`Invoice Preview`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Invoices`)),
          to: '/ac/invoices',
        },
        {
          text: computed(() => i18nT(`Preview Invoice`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ac/invoices/create/:type?',
    name: 'create-invoice',
    component: () => import('@/views/accounting/Invoices/EditInvoice.vue'),
    meta: {
      navActiveLink: 'invoices',
      module: 'finances',
      mode: 'write',
      pageTitle: computed(() => i18nT(`Invoices`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Invoices`)),
          to: '/ac/invoices',
        },
        {
          text: computed(() => i18nT(`Create Invoice`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ac/invoices/create/:quoteId?',
    name: 'create-quote-invoice',
    component: () => import('@/views/accounting/Invoices/EditInvoice.vue'),
    meta: {
      navActiveLink: 'invoices',
      module: 'finances',
      mode: 'write',
      pageTitle: computed(() => i18nT(`Invoices`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Invoices`)),
          to: '/ac/invoices',
        },
        {
          text: computed(() => i18nT(`Create Invoice`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ac/invoices/edit/:id',
    name: 'edit-invoice',
    component: () => import('@/views/accounting/Invoices/EditInvoice.vue'),
    meta: {
      navActiveLink: 'invoices',
      module: 'payments',
      mode: 'write',
      pageTitle: computed(() => i18nT(`Edit Invoice`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Invoices`)),
          to: '/ac/invoices',
        },
        {
          text: computed(() => i18nT(`Edit Invoice`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ac/credit-note/create/:invoiceId?',
    name: 'create-credit-note',
    component: () => import('@/views/accounting/Invoices/EditCreditNote.vue'),
    meta: {
      navActiveLink: 'invoices',
      module: 'finances',
      mode: 'write',
      pageTitle: computed(() => i18nT(`Invoices`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Invoices`)),
          to: '/ac/invoices',
        },
        {
          text: computed(() => i18nT(`Create credit / debit note`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ac/credit-note/edit/:id',
    name: 'edit-credit-note',
    component: () => import('@/views/accounting/Invoices/EditCreditNote.vue'),
    meta: {
      navActiveLink: 'invoices',
      module: 'payments',
      mode: 'write',
      pageTitle: computed(() => i18nT(`Edit Invoice`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Invoices`)),
          to: '/ac/invoices',
        },
        {
          text: computed(() => i18nT(`Edit credit note`)),
          active: true,
        },
      ],
    },
  },


  {
    path: '/ac/vendors',
    name: 'vendors',
    component: () => import('@/views/accounting/Vendors/VendorList.vue'),
    meta: {
      module: 'vendors',
      pageTitle: computed(() => i18nT(`Clients & Vendors`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Clients & Vendors`)),
          to: '/ac/vendors',
        },
      ],
    },
  },
  {
    path: '/ac/vendors/create',
    name: 'create-vendor',
    component: () => import('@/views/accounting/Vendors/EditVendor.vue'),
    meta: {
      module: 'vendors',
      mode: 'write',
      navActiveLink: 'vendors',
      pageTitle: computed(() => i18nT(`Edit Client / Vendor`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Clients & Vendors`)),
          to: '/ac/vendors',
        },
        {
          text: computed(() => i18nT(`Edit Client / Vendor`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ac/vendors/edit/:id',
    name: 'edit-vendor',
    component: () => import('@/views/accounting/Vendors/EditVendor.vue'),
    meta: {
      module: 'vendors',
      mode: 'write',
      navActiveLink: 'vendors',
      pageTitle: computed(() => i18nT(`Edit Client / Vendor`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Clients & Vendors`)),
          to: '/ac/vendors',
        },
        {
          text: computed(() => i18nT(`Edit Client / Vendor`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ac/transactions',
    name: 'transactions',
    component: () =>
        import('@/views/accounting/Transactions/TransactionList.vue'),
    meta: {
      module: 'bank',
      pageTitle: computed(() => i18nT(`Transactions`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Bank`)),
          to: {name: 'bank-accounts'}
        },
        {
          text: computed(() => i18nT(`Transactions`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ac/bank-accounts',
    name: 'bank-accounts',
    component: () => import('@/views/accounting/Bank/Accounts/AccountList.vue'),
    beforeEnter(to, _, next) {
      if (to.redirectedFrom && to.redirectedFrom.includes('powens_connect')) {
        to.meta.type='powens'
      } else {
        to.meta.type=''
      }
      next()
    },
    meta: {
      module: 'bank',
      template: 1,
      pageTitle: computed(() => i18nT(`Accounts`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Bank accounts`)),
          active: true,
        },
      ],

    },
  },
  {
    path: '/ac/bank-accounts/powens_connect',
    redirect: '/ac/bank-accounts',
    name: 'bank-accounts',
    component: () => import('@/views/accounting/Bank/Accounts/AccountList.vue'),
    meta: {
      type: 'powens',
      module: 'bank',
      template: 1,
      pageTitle: computed(() => i18nT(`Accounts`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Bank accounts`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ac/quotes',
    name: 'quotes',

    component: () => import('@/views/accounting/Quotes/QuotesList.vue'),
    meta: {
      module: 'invoices',
      navActiveLink: 'quotes',
      template: 1,
      pageTitle: computed(() => i18nT(`Quotes`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Quotes`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ac/quotes/create',
    name: 'quote-create',
    component: () => import('@/views/accounting/Quotes/EditQuote.vue'),
    meta: {
      navActiveLink: 'quotes',
      module: 'invoices',
      template: 1,
      pageTitle: computed(() => i18nT(`Edit Quote`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Quotes`)),
          to: '/ac/quotes',
        },
        {
          text: computed(() => i18nT(`Edit Quote`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ac/quotes/edit/:id',
    name: 'quote-edit',
    component: () => import('@/views/accounting/Quotes/EditQuote.vue'),
    meta: {
      navActiveLink: 'quotes',
      module: 'invoices',
      template: 1,
      pageTitle: computed(() => i18nT(`Edit Quote`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Quotes`)),
          to: '/ac/quotes',
        },
        {
          text: computed(() => i18nT(`Edit Quote`)),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ac/quote/preview/:id',
    name: 'quote-preview',
    component: () => import('@/views/accounting/Quotes/QuotePreview.vue'),
    meta: {
      module: 'invoices',
      navActiveLink: 'quotes',
      pageTitle: computed(() => i18nT(`Quote Preview`)),
      breadcrumb: [
        {
          text: computed(() => i18nT(`Quotes`)),
          to: '/ac/quotes',
        },
        {
          text: computed(() => i18nT(`Preview Quote`)),
          active: true,
        },
      ],
    },
  },
];

/* eslint-enable */
